import { createStore } from "vuex";

export default createStore({
  state: {
    secret: "qiksfykekayv",
    faced: false,
    hasFacedData: false
  },
  getters: {
    faced: function (state) {
      return state.faced
    }
  },
  mutations: {
    setFaced(state, pload) {
      state.faced = pload
    },
    setHasFacedData(state, pload) {
      console.log(11)
      state.hasFacedData = pload
    }
  },
  actions: {},
  modules: {},
});
