import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "login",
    meta: { title: "登录" },
    component: () => import("@/views/login/Index.vue")
  },
  {
    path: "/main",
    name: "main",
    meta: { title: "特种工学习平台" },
    component: () => import("@/compoments/layout/main.vue"),
    children: [
      {
        path: "/course",
        name: "course",
        meta: { title: "课程学习" },
        component: () => import("@/views/course/Index.vue")
      },
      {
        path: "/practise",
        name: "practise",
        meta: { title: "模拟考试" },
        component: () => import("@/views/practise/index.vue")
      },
      {
        path: "/courseDetail",
        name: "courseDetail",
        meta: { title: "课程详情" },
        component: () => import("@/views/course/courseDetail.vue")
      },
      {
        path: "/user",
        name: "prsonialCenter",
        meta: { title: "用户中心" },
        component: () => import("@/views/user/Index.vue")
      },
      {
        path: "/face",
        name: "face",
        meta: { title: "人脸智能识别" },
        component: () => import("@/views/user/Face.vue")
      },
      {
        path: "/exam",
        name: "exam",
        meta: { title: "考试预约" },
        component: () => import("@/views/exam/Index.vue")
      }
    ]
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, form, next) => {
  window.document.title = to.meta.title;
  next();
});

export default router;
