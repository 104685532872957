<template>
  <ConfigProvider :locale="locale">
    <router-view />
  </ConfigProvider>
</template>

<script>
import { ConfigProvider } from 'ant-design-vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';

export default {
  name: 'App',
  components: {
    ConfigProvider
  },
  data() {
    return {
      locale: zhCN
    };
  }
};
</script>